<template>
    <div class="market-body">
        <div class="mark-title">活动总结汇报</div>
        <div class="m-t-10">
          {{detailData.stationName}}
        </div>
        <van-cell title="活动时间" is-link arrow-direction="down"
                  :value="submitPar.activityTime"
                  @click="detailData.status==='finished'?showActivityTime=false:showActivityTime=true"/>
        <van-calendar v-model="showActivityTime"
                      :min-date="minDate"
                      color="#417C53"
                      @confirm="onConfirm" />

        <van-cell title="活动类型" is-link arrow-direction="down"
                  :value="activityTypeTxt" placeholder="请选择"
                  @click="detailData.status==='finished'?showActivityType=false:showActivityType=true"/>
        <van-popup v-model="showActivityType" round position="bottom">
            <van-picker
                    show-toolbar
                    :columns="activityType"
                    @cancel="showActivityType = false"
                    @confirm="selectActivityType"
            />
        </van-popup>

        <van-field v-model="submitPar.participant" :disabled="detailData.status==='finished'" label="参与人" placeholder="请输入"/>

        <van-cell title="银行配合度" is-link arrow-direction="down"
                  :value="bankCooperationTxt" placeholder="请选择"
                  @click="detailData.status==='finished'?showBankCoordination=false:showBankCoordination=true"/>
        <van-popup v-model="showBankCoordination" round position="bottom">
            <van-picker
                    show-toolbar
                    :columns="bankCoordination"
                    @cancel="showBankCoordination = false"
                    @confirm="selectBankCooperation"
            />
        </van-popup>

        <van-cell v-if="submitPar.bankCooperation !== 'none'" title="银行是否提供物品">
            <van-switch v-model="submitPar.bankHasProvide" size='20'
                        active-color="#417C53" :disabled="detailData.status==='finished'" />
        </van-cell>
        <van-field
                v-if="submitPar.bankCooperation !== 'none' && submitPar.bankHasProvide"
                v-model="submitPar.bankItemDetail"
                rows="1"
                autosize
                label="银行物品明细"
                type="textarea"
                placeholder="请输入物品明细"
        />
        <van-cell title="站长配合度" is-link arrow-direction="down"
                  :value="masterCooperationTxt" placeholder="请选择"
                  @click="detailData.status==='finished'?showStationCoordination=false:showStationCoordination=true"/>
        <van-popup v-model="showStationCoordination" round position="bottom">
            <van-picker
                    show-toolbar
                    :columns="stationCoordination"
                    @cancel="showStationCoordination = false"
                    @confirm="selectMasterCooperationTxt"
            />
        </van-popup>
        <van-cell title="站长是否提供物品">
            <van-switch v-model="submitPar.masterHasProvide" size='20'
                        active-color="#417C53" :disabled="detailData.status==='finished'" />
        </van-cell>
        <van-field
                v-if="submitPar.masterHasProvide"
                v-model="submitPar.masterItemDetail"
                rows="1"
                autosize
                label="站长物品明细"
                type="textarea"
                placeholder="请输入物品明细"
        />
        <van-cell>
            <div>活动投入[合计: {{amountNum}} 元]</div>
            <div v-for="(item,idx) in putIntoList" :key="idx">
                <div class="putInto-box">
                    <span>{{item.pitemName}} - {{item.itemName}} </span>
                    <span>{{item.unitPrice}}(元) * {{item.num}}</span>
                    <van-field
                            :disabled="detailData.status==='finished'"
                            v-model="item.useNum"
                            placeholder="数量"
                            type="number"
                            :formatter="formatter"
                            style="border:1px solid #7E8185;
                            width:60px;"> </van-field>
                </div>
            </div>
        </van-cell>
        <van-cell>
            <div>活动产出</div>
        </van-cell>
            <van-field label="1.邀约客户数"
                       v-model="submitPar.invitedClient"
                       type="number" placeholder="请输入邀约客户数"
                       :formatter="formatter" :disabled="detailData.status==='finished'"/>
            <van-field label="2.到场客户"
                       v-model="submitPar.onsiteClient"
                       type="number" placeholder="请输入到场客户数"
                       :formatter="formatter" :disabled="detailData.status==='finished'"/>
            <van-field label="3.开卡数量"
                       v-model="submitPar.cardNum"
                       type="number" placeholder="请输入开卡数量"
                       :formatter="formatter" :disabled="detailData.status==='finished'"/>
            <van-field label="4.新增余额"
                       v-model="depositAmount"
                       type="number"
                       placeholder="请输入新增余额"
                       :disabled="detailData.status==='finished'"
            />
            <van-field label="5.新增群人数"
                       v-model="submitPar.addedGroup"
                       type="number" placeholder="请输入新增群人数"
                       :formatter="formatter" :disabled="detailData.status==='finished'"/>
            <van-field label="6.其他" v-model="submitPar.other" type="text" :disabled="detailData.status==='finished'"/>
        <van-cell>
            <div>图片上传</div>
            <van-uploader class="margin-l-20"
                          max-count="3"
                          v-model="fileList"
                          :after-read="afterRead"
                          :before-delete="deletePic"
                          :disabled="detailData.status==='finished'"

            />
        </van-cell>
        <van-cell style="margin-bottom: 80px;">
            <div>活动情况</div>
            <van-field
                    v-model="submitPar.activityCondition"
                    rows="2"
                    autosize
                    type="textarea"
                    placeholder="请输入"
                    style="border:1px solid #7E8185"
                    :disabled="detailData.status==='finished'"

            />
        </van-cell>

        <div class="nextBtn flex" >
            <van-button class="publicBtnActive left-btn"
                        v-if="detailData.status !== 'finished'"
                        @click="saveReport"
            >保存</van-button>
            <van-button class="publicBtnActive"
                        style="margin-right: 0.24rem;"
                        v-if="detailData.status !== 'finished'"
                        @click="submitReport"
                       >提交汇报</van-button>
            <van-button class="publicBtnActive"
                        v-if="detailData.status === 'finished' && detailData.allowAdd"
                        @click="newlyReport"
                        >新增汇报</van-button>
        </div>

    </div>

</template>

<script>
    import {taskDetail,saveFileInfo,saveOrSubmit,addReport}  from '@/utils/api';
    import common from "@/utils/common";
    import { Toast } from 'vant'
    export default {
        name: "marketing.vue",
        data(){
            return{
                minDate: new Date(2000, 0, 1),
                showActivityTime:false,
                activityType:[
                    {text:'请选择',value:''},
                    {text:'试营业活动',value:'trialOperation'},
                    {text:'基础营销活动',value:'basicMarketing'},
                    {text:'部门方案营销活动',value:'deptProgram'},
                    {text:'开业及揭牌活动',value:'openingAndUnveiling'},
                    {text:'综合及特殊营销活动',value:'integratedAndSpecial'}
                ],
                activityType2:{
                    ' ':'请选择',
                    'trialOperation':'试营业活动',
                    'basicMarketing':'基础营销活动',
                    'deptProgram':'部门方案营销活动',
                    'openingAndUnveiling':'开业及揭牌活动',
                    'integratedAndSpecial':'综合及特殊营销活动'
                },
                showActivityType:false,
                bankCoordination:[
                    {text:'请选择',value:''},
                    {text:'无需配合',value:'none'},
                    {text:'配合度高',value:'high'},
                    {text:'配合度一般',value:'normal'},
                    {text:'配合度较差',value:'low'},
                ],
                coordinationObj:{
                    ' ':'请选择',
                    'none':'无需配合',
                    'high':'配合度高',
                    'normal':'配合度一般',
                    'low':'配合度较差'
                },
                showBankCoordination:false,
                activityTypeTxt:'',//任务类型text
                bankCooperationTxt:'',//银行配合度text
                masterCooperationTxt:'',//站长配合度text
                depositAmount:'',//存款金额
                submitPar:{
                    activityTime:'',//活动时间
                    activityType:'',//活动类型
                    participant:'',//参与人
                    bankCooperation:'',//银行配合度
                    bankHasProvide:false,//银行是否提供物品
                    bankItemDetail:'',//银行物品明细
                    masterCooperation:'',//站长配合度
                    masterHasProvide:false,//站长是否提供物品
                    masterItemDetail:'',//站长物品明细
                    // activityInput:'',//活动投入
                    // amount:0,//合计
                    invitedClient:null,//邀约客户数
                    onsiteClient:null,//到场客户
                    cardNum:null,//开卡数量

                    addedGroup:null,//新增群人数
                    other:'',//其他
                    // pic:[],//图片
                    activityCondition:'',//活动情况
                    //id:'',//详情id
                    // stationNo:'',//站点编号（提交时传）
                    // createTime:'',//创建时间
                },
                pic:[],//图片
                stationCoordination:[
                    {text:'请选择',value:''},
                    {text:'配合度高',value:'high'},
                    {text:'配合度一般',value:'normal'},
                    {text:'配合度较差',value:'low'},
                ],
                showStationCoordination:false,
                putIntoList:[],
                fileList:[],
                detailData:[],
                newDate:'',
                newTaskNo:'',
            }
        },
        created() {
            //获取当前时间
            let myDate = new Date();
            let startY = myDate.getFullYear();
            let startM = myDate.getMonth() + 1;
            this.newDate = startY +'-'+ (startM < 10 ? '0' + startM :startM);
            this.getTaskDetail();
        },
        computed: {
            amountNum(){
                let arr = this.putIntoList, totalAmount = 0;
                arr.forEach(item=>{
                    if(item.useNum){
                        totalAmount += item.useNum * item.unitPrice
                    }
                })
                totalAmount=parseFloat(totalAmount).toFixed(2);
                return totalAmount;
            }
        },
        methods:{
            formatter(value) {
                // 过滤输入的数字
                return value.replace(/\./, '');
            },
            //获取详情
            getTaskDetail(taskNo){
                this.pic = [];
                this.fileList = [];
                this.putIntoList = [];
                let par = '';
                if(taskNo){
                    par = taskNo
                }else{
                    par = this.$route.query.taskNo;
                }
                taskDetail({taskNo:par}).then(res=>{
                    console.log('营销活动详情', res);
                    if(res.code === 200 && res.data.businessCode === 200){
                        this.detailData = res.data.data;
                        this.putIntoList = res.data.data.activityInputList;
                        let data =  res.data.data;
                        this.activityTypeTxt = this.activityType2[data.activityType],//任务类型text
                        this.bankCooperationTxt = this.coordinationObj[data.bankCooperation],//银行配合度text
                        this.masterCooperationTxt = this.coordinationObj[data.masterCooperation],//站长配合度text
                        this.depositAmount = data.depositAmount,//存款金额
                        this.submitPar = {
                                activityTime:data.activityTime,//活动时间
                                activityType:data.activityType,//活动类型
                                participant:data.participant,//参与人
                                bankCooperation:data.bankCooperation,//银行配合度
                                bankHasProvide:data.bankHasProvide,//银行是否提供物品
                                bankItemDetail:data.bankItemDetail,//银行物品明细
                                masterCooperation:data.masterCooperation,//站长配合度
                                masterHasProvide:data.masterHasProvide,//站长是否提供物品
                                masterItemDetail:data.masterItemDetail,//站长物品明细
                                invitedClient:data.invitedClient,//邀约客户数
                                onsiteClient:data.onsiteClient,//到场客户
                                cardNum:data.cardNum,//开卡数量
                                addedGroup:data.addedGroup,//新增群人数
                                other:data.other,//其他
                                activityCondition:data.activityCondition,//活动情况
                        }
                        if(data.pic){
                            this.pic = JSON.parse(data.pic)//图片
                            this.handlePic(this.pic);
                        }
                    }

                })
            },
            //处理回显图片
            handlePic(imgUrlArr){
                let url ='' ;
                for(let i= 0; i< imgUrlArr.length; i++){
                    if(process.env.NODE_ENV === 'production'){
                        url = window.location.origin + '/file/' + imgUrlArr[i] + '?Authorization=' + common.getItem('wtToken')
                    }else{
                        url = 'http://124.70.167.116:20081/file/' +  imgUrlArr[i] + '?Authorization=' + common.getItem('wtToken')
                    }
                    this.fileList.push({url:url})
                }
            },
            //选择时间
            formatDate(date) {
                return `${date.getFullYear()}-${(date.getMonth() + 1)
                < 10 ?'0'+(date.getMonth() + 1):(date.getMonth() + 1)}-${date.getDate() < 10 ? '0'+date.getDate():date.getDate()}`;
            },
            onConfirm(date) {
                this.showActivityTime = false;
                this.submitPar.activityTime = this.formatDate(date);
            },
            //选择活动类型
            selectActivityType(value){
                this.activityTypeTxt= value.text;
                this.submitPar.activityType = value.value;
                this.showActivityType = false;
            },
            //选择银行配合度
            selectBankCooperation(value){
                this.bankCooperationTxt= value.text;
                this.submitPar.bankCooperation = value.value;
                this.showBankCoordination = false;
            },
            //选择站长配合度
            selectMasterCooperationTxt(value){
                this.masterCooperationTxt= value.text;
                this.submitPar.masterCooperation = value.value;
                this.showStationCoordination = false;
            },
            afterRead(file, detail){
                // 大于15M
                if(file.file.size > 15 * 1024 * 1024){
                    Toast('图片太大，请上传小于15M的图片')
                    return
                }
                //验证图片格式
                if(!common.imageVerify(file.file.type)){
                    this.fileList.splice(detail.index, 1)
                    Toast('上传图片必须为png或者jpg格式')
                    return
                }
                // 小于100K不进行压缩
                if(file.file.size < 100 * 1024){
                    this.saveFile(file.content)
                    return
                }
                //读取文件内容
                let reader = new FileReader();
                //方法可以将读取到的文件编码成DataURL,可以将资料(例如图片,excel文件)内嵌在网页之中，不用放到外部文件
                reader.readAsDataURL(file.file)
                let _this = this
                reader.onload = function () {
                    let content= file.content //图片的src，base64格式
                    let img = new Image()
                    img.src = content
                    // _this.perviewImage = content;
                    img.onload = function() {
                        common.compress(img, (dataURL)=>{
                            _this.saveFile(dataURL)
                        })
                    }
                }

            },
            deletePic (file, pic) {
                this.fileList.splice(pic.index,1)
                if (file.url){
                    this.pic.splice(pic.index,1)
                }
            },
            saveFile(dataURL) {
                let params = {
                    fileType:'activityImg',
                    fileContent: dataURL,
                    stationNo:this.$route.query.stationNo,
                    picOwnNo: common.getItem('uniqueNo'),
                }
                saveFileInfo(params).then(res=>{
                    console.log('res', res);
                    if(res.code === 200 && res.data.businessCode === 200){
                        if(this.pic.length < 4){
                            this.pic.push(res.data.filePath)
                        }
                    }
                })
            },
            submitReport(){
                if(!this.submitPar.activityTime){
                    this.$message.warning('请选择活动时间!');
                    return;
                }
                if(!this.submitPar.activityType){
                    this.$message.warning('请选择活动类型!');
                    return;
                }
                if(!this.submitPar.bankCooperation){
                    this.$message.warning('请选择银行配合度!');
                    return;
                }
                if(!this.submitPar.masterCooperation){
                    this.$message.warning('请选择站长配合度!');
                    return;
                }
                if(this.pic.length === 0 ){
                    this.$message.warning('请上传图片!');
                    return;
                }
                if(!this.submitPar.activityCondition){
                    this.$message.warning('请输入活动情况!');
                    return;
                }
                let arr = this.putIntoList;
                let putIntoArr = [];
                for(let i = 0; i < arr.length; i++){
                    if(arr[i].useNum){
                        if(arr[i].useNum==='0'){
                            Toast('使用量必须大于0!');
                            return;
                        }
                        if(arr[i].useNum > arr[i].num){
                            Toast('使用量超出库存!');
                            return;
                        }
                        putIntoArr.push(arr[i])
                    }
                }
                let newPar = this.newTaskNo ? this.newTaskNo : this.$route.query.taskNo;
                let data = {
                    taskNo:newPar,//必须 任务编号
                    stationNo:this.$route.query.stationNo,//站点编号（提交时传）
                    isSubmit:'1',
                    id: this.detailData.id,//详情id
                    createTime:this.detailData.createTime,//创建时间
                    activityInput:JSON.stringify(putIntoArr),
                    pic: this.pic.length > 0 ? JSON.stringify(this.pic):'',
                    amount:this.amountNum,
                    depositAmount:this.depositAmount?parseFloat(this.depositAmount).toFixed(2):'',
                    ...this.submitPar,
                }
                saveOrSubmit(data).then(res=>{
                    console.log('提交汇报', res)
                    if(res.code === 200 && res.data.businessCode === 200){
                        this.$message.success('提交汇报成功!')
                        this.getTaskDetail(newPar);
                    }
                })

            },
            //保存
            saveReport(){
                let arr = this.putIntoList;
                let putIntoArr = [];
                for(let i = 0; i < arr.length; i++){
                    if(arr[i].useNum){
                        if(arr[i].useNum==='0'){
                            Toast('使用量必须大于0!');
                            return;
                        }
                        if(arr[i].useNum > arr[i].num){
                            Toast('使用量超出库存!');
                            return;
                        }
                        putIntoArr.push(arr[i])
                    }
                }
                let newPar = this.newTaskNo ? this.newTaskNo : this.$route.query.taskNo;
                let data = {
                    taskNo:newPar,//必须 任务编号
                    isSubmit:'',
                    id: this.detailData.id,//详情id
                    createTime:this.detailData.createTime,//创建时间
                    activityInput:JSON.stringify(putIntoArr),
                    pic:this.pic.length > 0 ? JSON.stringify(this.pic):'',
                    amount:this.amountNum,
                    depositAmount:this.depositAmount?parseFloat(this.depositAmount).toFixed(2):'',
                    ...this.submitPar,
                }
                saveOrSubmit(data).then(res=>{
                    if(res.code === 200 && res.data.businessCode === 200){
                        this.$message.success('保存成功!')
                        this.getTaskDetail(newPar);
                    }
                })
            },
            //新增
            newlyReport(){
                this.newTaskNo = '';
                addReport({
                    stationNo:this.$route.query.stationNo,//站点编号
                }).then(res=>{
                    if(res.code === 200 && res.data.businessCode === 200){
                        this.getTaskDetail(res.data.data.taskNo);
                        this.newTaskNo = res.data.data.taskNo;
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .market-body{
        padding: 20px  10px 0  10px;
        .flex {
            display: flex;
        }
        .mark-title{
            font-size:15px;
            font-weight: bold;
        }
        .putInto-box{
            padding: 15px;
            display: flex;
            justify-content: space-between;
            .van-cell{
                padding:0;
            }
        }
        .margin-l-20{
            margin-left: 20px;
        }
        .nextBtn {
            width: 100%;
            padding: 12px;
            background: #f7f8f9;
            position: absolute;
            bottom: 0;
            left: 0;
            .left-btn{
                background: #fff;
                color:#333;
                border: 1px solid #E5E5E5;
                margin-right: 0.24rem;
            }
        }
        .m-t-10{
            margin:10px 5px;
            font-weight: bold;
        }
    }

</style>
